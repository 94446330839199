import React, { useState } from 'react';

const UcSection = ({ items, selected, setSelected, firstInput, validateFirstInput, secondInput, validateSecondInput, errors, validation, getClassName }) => {
    return (
        <>
            <div className="col-12 mb-2">
                <label>لیست یوسی</label>
                <select className="form-control mt-2" name="product_id" value={selected} onChange={e => setSelected(e.target.value)}>
                    {items.map(item => <option key={item.key} value={item.key}>{item.value}</option>)};
                </select>
            </div>

            <div className="col-12 mb-2">
                <img className="border border-secondary p-1 rounded w-100" src="/web/images/index-img/account-image.png" />
                <div className="clearfix mt-3">
                    <p className="float-right">آی دی اکانت پابجی مثال:</p>
                    <p className="float-left text-primary">ID: 5159583447</p>
                </div>
                <input name="game_id" type="number" className={`form-control ${getClassName(validation.game_id)}`}
                    placeholder="آیدی 9 رقمی"
                    value={firstInput} onChange={e => validateFirstInput(e.target.value)}
                    pattern="[0-9]{9, }" autoComplete="off"
                />
                {errors.game_id && <div className="invalid-feedback">{errors.game_id}</div>}
            </div>

            <div className="col-12 mb-2" id="second_input">
                <div className="clearfix mt-3">
                    <p className="float-right">اسم اکانت پابجی مثال:</p>
                    <p className="float-left text-primary">Name: Rebel&Last</p>
                </div>
                <input name="game_name" type="text" className={`form-control ${getClassName(validation.game_name)}`} placeholder="نام بازیکن"
                    value={secondInput} onChange={e => validateSecondInput(e.target.value)}
                    autoComplete="off"
                />
                {errors.game_name && <div className="invalid-feedback">{errors.game_name}</div>}
            </div>
        </>
    );
}

export default UcSection;
