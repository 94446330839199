import React, {useState, useEffect} from "react";
import {render} from 'react-dom';
import UcSection from "./UcSection";
import PrimePlusSection from "./PrimePlusSection";
import axios from 'axios';

const useDidMount = () => {
    const [didMount, setDidMount] = useState(false)
    useEffect(() => {
        setDidMount(true)
    }, [])

    return didMount
}

const SubmitOrder = props => {

    let bladeErrors = {...JSON.parse(props.errors)};
    Object.keys(bladeErrors).map(function (key) {
        bladeErrors[key] = bladeErrors[key][0];
    });

    const initialErrors = {
        full_name: null,
        mobile: null,
        game_id: null,
        game_name: null,
        account_username: null,
        account_password: null,
        mobile_whatsapp: null,
        ...bladeErrors,
    };

    let tempError = {...initialErrors};
    Object.keys(tempError).map(function (key) {
        tempError[key] = (tempError[key] != null) ? false : null;
    });
    const initialValidation = {
        full_name: null,
        mobile: null,
        game_id: null,
        game_name: null,
        account_username: null,
        account_password: null,
        mobile_whatsapp: null,
        ...tempError
    };
    const [loading, setLoading] = useState(false);

    const [accept, setAccept] = useState(false);
    const [discount, setDiscount] = useState('');
    const [hasDiscount, setHasDiscount] = useState(false);
    const [discountAmount, setDiscountAmount] = useState(0);

    const [validation, setValidation] = useState(initialValidation);
    const [errors, setErrors] = useState(initialErrors);

    const didMount = useDidMount();
    const [type, setType] = useState(props.type);
    const [items, setItems] = useState(JSON.parse(props.items));

    const [selected, setSelected] = useState(props.selected.toString().length == 0 ? items[0][0].key : props.selected);
    const [selectedItem, setSelectedItem] = useState(null);

    const [primeType, setPrimeType] = useState(props.primeType);
    const [mobileWhatsapp, setMobileWhatsapp] = useState(props.mobileWhatsapp)

    const [fullName, setFullName] = useState(props.fullName);
    const [mobile, setMobile] = useState(props.mobile);

    const [firstInput, setFirstInput] = useState(props.firstInput);
    const [secondInput, setSecondInput] = useState(props.secondInput);

    useEffect(() => {
        if (didMount) {
            setSelected(type == 'uc' ? items[0][0].key : items[1][0].key);
            setFirstInput('');
            setSecondInput('');
            let tempInputs;
            if (type == 'uc') {
                tempInputs = {
                    game_id: null,
                    game_name: null,
                };
            } else {
                tempInputs = {
                    account_username: null,
                    account_password: null,
                    mobileWhatsapp: null,
                };
            }
            setValidation({
                ...validation,
                ...tempInputs,
            });
        }
    }, [type]);

    useEffect(() => {
        const index = type == 'uc' ? 0 : 1;
        setSelectedItem(items[index].find((item) => item.key == selected));
    }, [selected]);

    const handleDiscount = () => {
        setLoading(true);

        axios.post("/get_discount", {"discount_code": discount })
            .then((response) => {
                setHasDiscount(response.data.result);
                setDiscountAmount(response.data.amount);
                setLoading(false);

                let min_price = parseFloat(response.data.min_price);
                let price = selectedItem?.price;

                if (price < min_price) {
                    let rate = response.data.amount / min_price;
                    rate = Math.round((rate + Number.EPSILON) * 100) / 100;
                    setDiscountAmount(Math.ceil(price * rate));
                }
            }).catch(error => {
                setLoading(false);
            });
    }

    const get_price = (price) => {
        if (hasDiscount)
        {
            price -= discountAmount;
        }
        return price && format_number(price);
    }

    const format_number = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " تومان";
    }

    const validateFullName = (value) => {
        setFullName(value);
        setValidation({
            ...validation,
            full_name: value.length >= 3,
        });
    }

    const validateMobile = (value) => {
        setMobile(value);
        setValidation({
            ...validation,
            mobile: /^09[0-9]{9}$/.test(value),
        });
    }

    const validateGameId = (value) => {
        setFirstInput(value);
        setValidation({
            ...validation,
            game_id: /^[0-9]{9,}$/.test(value),
        });
    }

    const validateGameName = (value) => {
        setSecondInput(value);
        setValidation({
            ...validation,
            game_name: value.length >= 3,
        });
    }

    const validateAccountUsername = (value) => {
        setFirstInput(value);
        setValidation({
            ...validation,
            account_username: (primeType == 'twitter') ? value.length >= 3 : /^09[0-9]{9}$/.test(value) || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value),
        });
    }

    const validateAccountPassword = (value) => {
        setSecondInput(value);
        setValidation({
            ...validation,
            account_password: value.length >= 6,
        });
    }

    const validateMobileWhatapp = (value) => {
        setMobileWhatsapp(value);
        setValidation({
            ...validation,
            mobile_whatsapp: /^09[0-9]{9}$/.test(value),
        });
    };

    const getClassName = (is_valid) => {
        if (is_valid === true)
            return 'is-valid';

        if (is_valid === false)
            return 'is-invalid';

        if (is_valid === null)
            return '';

    }

    return (
        <div className="row align-items-start box-main-card-master mt-3 p-2 p-md-3 rounded position-relative">
            <div className="col-12 p-2 p-md-0 pb-md-2 mb-3 border-bottom-card">
                <h2 className="font-weight-bold font-size-18">
                    ثبت سفارش
                </h2>
            </div>
            <div className="row w-100 justify-content-between mx-0">

                <div className="col-12 mb-2">
                    <label>نام و نام خانوادگی</label>
                    <input name="full_name" type="text" className={`form-control ${getClassName(validation.full_name)}`}
                           value={fullName} onChange={e => validateFullName(e.target.value)}
                           autoComplete="off"
                    />
                    {errors.full_name && <div className="invalid-feedback">{errors.full_name}</div>}
                </div>

                <div className="col-12 mb-2">
                    <label>موبایل</label>
                    <input name="mobile" type="number" className={`form-control ${getClassName(validation.mobile)}`}
                           placeholder="09123456789 همراه با صفر"
                           pattern="[0-9]{11}"
                           value={mobile} onChange={e => validateMobile(e.target.value)}
                           autoComplete="off"
                    />
                    {errors.mobile && <div className="invalid-feedback">{errors.mobile}</div>}
                </div>

                <div className="col-12 mb-2">
                    <div className="form-check form-check-inline">
                        <label className="form-check-label">
                            <input className="form-check-input"
                                   name="type" type="radio" value="uc"
                                   checked={type == 'uc'} onChange={e => setType(e.target.value)}
                            />
                            یوسی
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <label className="form-check-label">
                            <input className="form-check-input"
                                   name="type" type="radio"
                                   value="prime_plus"
                                   checked={type == 'prime_plus'} onChange={e => setType(e.target.value)}
                            />
                            پرایم پلاس
                        </label>
                    </div>
                </div>

                {type == 'uc' && <UcSection items={items[0]} selected={selected} setSelected={setSelected}
                                            firstInput={firstInput} validateFirstInput={validateGameId} secondInput={secondInput}
                                            validateSecondInput={validateGameName}
                                            errors={errors} validation={validation} getClassName={getClassName}
                />}
                {type == 'prime_plus' &&
                <PrimePlusSection items={items[1]} selected={selected} setSelected={setSelected} primeType={primeType} setPrimeType={setPrimeType}
                                  mobileWhatsapp={mobileWhatsapp} validateMobileWhatsapp={validateMobileWhatapp}
                                  firstInput={firstInput} validateFirstInput={validateAccountUsername} secondInput={secondInput}
                                  validateSecondInput={validateAccountPassword}
                                  errors={errors} validation={validation} getClassName={getClassName}
                />}
                <div className="col-12 mb-2">
                    <div
                        className="align-items-start bg-gray-0 box-main-card-master mt-3 position-relative rounded row">
                        <div className="col-12 p-2 p-md-0 pb-md-2 mb-3 border-bottom-card">
                            <h2 className="font-size-20 font-weight-bold text-center pt-2">
                                صورت حساب
                            </h2>
                        </div>
                        <div className="row w-100 justify-content-between mx-0">
                            <div className="col-12 my-1">نام محصول : <span>{selectedItem?.value}</span></div>
                            <div className="col-12 my-1">کد تخفیف :</div>
                            <div className="input-group col-12 mb-3 mt-1">
                                <div className="input-group-append">
                                    <span className="input-group-text rounded-right"><i className="fas fa-shopping-cart"></i></span>
                                </div>
                                <input type="text" name="discount_code" className="form-control" value={discount} onChange={e => setDiscount(e.target.value)}/>
                                {!loading ? <div className="input-group-prepend">
                                    <button className="btn btn-primary rounded-left" type="button" onClick={handleDiscount}>اعمال کد</button>
                                </div> : <button className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    صبر کنید ...
                                </button>}
                            </div>
                            <div className="bg-gray-light border col-12 py-2 rounded-bottom text-center">قیمت نهایی :
                                <span>{get_price(selectedItem?.price)}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 mb-2">
                    <label>
                        <input type="checkbox" className="ml-1" value={accept} onChange={() => setAccept(!accept)}/>
                        فروش کلیه محصولات این سایت منحصرا توسط افراد بالای ۱۸ سال
                        امکانپذیر است<span className="text-danger">.بالای ۱۸ سال میباشم</span>.
                    </label>
                </div>

                {type == 'prime_plus' && <input type="hidden" name="prime_type" value={primeType}/>}
                <button type="button" className="btn btn-primary w-100 mt-2" data-toggle="modal"
                        data-target={(Object.values(validation).some(value => value === false) || Object.values(validation).every(value => value === null)) ? '' : (!accept) ? '' : '#review'}>
                    ثبت سفارش
                </button>
            </div>
            <div className="modal fade" id="review" tabIndex="-1" role="dialog" aria-labelledby="review" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">باز بینی</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row w-100 justify-content-between mx-0">
                                <div className="col-12 col-sm-6">نام و نام خانوادگی</div>
                                <div className="col-12 col-sm-6 text-left text-sm-right">{fullName}</div>
                                <div className="col-12 col-sm-6">موبایل</div>
                                <div className="col-12 col-sm-6 text-left text-sm-right">
                                    {mobile}
                                </div>
                                <div className="col-12 col-sm-6">محصول انتخاب شده</div>
                                <div className="col-12 col-sm-6 text-left text-sm-right">{selectedItem?.value}</div>
                                {hasDiscount && <>
                                    <div className="col-12 col-sm-6">قیمت</div>
                                    <div className="col-12 col-sm-6 text-left text-sm-right">{format_number(selectedItem?.price)}</div>
                                    </>}
                                {hasDiscount && <>
                                    <div className="col-12 col-sm-6">مقدار تخفیف</div>
                                    <div className="col-12 col-sm-6 text-left text-sm-right">{format_number(discountAmount)}</div>
                                    </>}
                                <div className="col-12 col-sm-6">قیمت نهایی جهت پرداخت</div>
                                <div className="col-12 col-sm-6 text-left text-sm-right">{get_price(selectedItem?.price)}</div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">بازگشت</button>
                            <button type="submit" className="btn btn-primary">تایید نهایی</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default SubmitOrder;

const el = document.getElementById("submit_order");
if (el) {
    render(<SubmitOrder {...el.dataset} />, el);
}
