import React, { useState } from 'react';

const PrimePlusSection = ({ items, selected, setSelected, primeType, mobileWhatsapp, validateMobileWhatsapp, setPrimeType, firstInput, validateFirstInput, secondInput, validateSecondInput, errors, validation, getClassName }) => {
    const classList = 'border border-info bg-green-a100';

    return (
        <>
            <div className="col-12 mb-2">
                <label>لیست پرایم پلاس</label>
                <select className="form-control mt-2" name="product_id" value={selected} onChange={e => setSelected(e.target.value)}>
                    {items.map(item => <option key={item.key} value={item.key}>{item.value}</option>)};
                </select>
            </div>
            <div className="col-12 mb-2">
                <div>اکانت بازی پابجی شما روی چی سیو شده : ؟</div>
                <div className="row my-2 border p-3 rounded">
                    <div className={`col p-1 text-center ${primeType == 'google_game_play' ? classList : ''}`}
                        onClick={() => setPrimeType('google_game_play')}>
                        <img className="w-75" src="/web/images/index-img/Play-Game-Google.svg" />
                        <span className="d-block mt-1 font-size-12 font-weight-bold">گیم پلی گوگل</span>
                    </div>
                    <div className={`col p-1 text-center ${primeType == 'facebook' ? classList : ''}`}
                        onClick={() => setPrimeType('facebook')}>
                        <img className="w-75" src="/web/images/index-img/Facebook.svg" />
                        <span className="d-block mt-1 font-size-12 font-weight-bold">فیس بوک</span>
                    </div>
                    <div className={`col p-1 text-center ${primeType == 'twitter' ? classList : ''}`}
                        onClick={() => setPrimeType('twitter')}>
                        <img className="w-75" src="/web/images/index-img/Twitter.svg" />
                        <span className="d-block mt-1 font-size-12 font-weight-bold">توییتر</span>
                    </div>
                    <div className={`col p-1 text-center ${primeType == 'email_link' ? classList : ''}`}
                        onClick={() => setPrimeType('email_link')}>
                        <img className="w-75" src="/web/images/index-img/Link-Email.svg" />
                        <span className="d-block mt-1 font-size-12 font-weight-bold">لینک کمکی</span>
                    </div>
                </div>
            </div>
            <div className="col-12 mb-2">
                <div className="clearfix mt-3">
                    <p className="float-right">شماره واتس اپ (WhatsApp) صاحب اکانت :</p>
                </div>
                <input name="mobile_whatsapp" type="text" className={`form-control ${getClassName(validation.mobile_whatsapp)}`} placeholder="WhatsApp Number"
                    value={mobileWhatsapp} onChange={e => validateMobileWhatsapp(e.target.value)}
                    autoComplete="off"
                />
                {errors.mobile_whatsapp && <div className="invalid-feedback">{errors.mobile_whatsapp}</div>}
            </div>
            <div className="col-12 mb-2">
                <div className="clearfix mt-3">
                    <p className="float-right">نام کاربری :</p>
                    <p className="float-left text-primary">Name@gmail.com</p>
                </div>
                <input name="account_username" type="text" className={`form-control ${getClassName(validation.account_username)}`} placeholder="user name"
                    value={firstInput} onChange={e => validateFirstInput(e.target.value)}
                    autoComplete="off"
                />
                {errors.account_username && <div className="invalid-feedback">{errors.account_username}</div>}
            </div>
            <div className="col-12 mb-2">
                <div className="clearfix mt-3">
                    <p className="float-right">رمز عبور :</p>
                    <p className="float-left text-primary">***********ASD</p>
                </div>
                <input name="account_password" type="password" className={`form-control ${getClassName(validation.account_password)}`} placeholder="password"
                    value={secondInput} onChange={e => validateSecondInput(e.target.value)}
                    autoComplete="off"
                />
                {errors.account_password && <div className="invalid-feedback">{errors.account_password}</div>}
            </div>
        </>
    );
}

export default PrimePlusSection;
